// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-news-template-js": () => import("./../../../src/components/templates/newsTemplate.js" /* webpackChunkName: "component---src-components-templates-news-template-js" */),
  "component---src-components-templates-team-template-js": () => import("./../../../src/components/templates/teamTemplate.js" /* webpackChunkName: "component---src-components-templates-team-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anfahrt-js": () => import("./../../../src/pages/anfahrt.js" /* webpackChunkName: "component---src-pages-anfahrt-js" */),
  "component---src-pages-fitness-gymnastik-js": () => import("./../../../src/pages/fitness_gymnastik.js" /* webpackChunkName: "component---src-pages-fitness-gymnastik-js" */),
  "component---src-pages-foerderverein-js": () => import("./../../../src/pages/foerderverein.js" /* webpackChunkName: "component---src-pages-foerderverein-js" */),
  "component---src-pages-fussball-js": () => import("./../../../src/pages/fussball.js" /* webpackChunkName: "component---src-pages-fussball-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kinderturnen-js": () => import("./../../../src/pages/kinderturnen.js" /* webpackChunkName: "component---src-pages-kinderturnen-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leitbild-js": () => import("./../../../src/pages/leitbild.js" /* webpackChunkName: "component---src-pages-leitbild-js" */),
  "component---src-pages-mitglied-werden-js": () => import("./../../../src/pages/mitglied-werden.js" /* webpackChunkName: "component---src-pages-mitglied-werden-js" */),
  "component---src-pages-mitmachen-js": () => import("./../../../src/pages/mitmachen.js" /* webpackChunkName: "component---src-pages-mitmachen-js" */),
  "component---src-pages-neuigkeiten-js": () => import("./../../../src/pages/neuigkeiten.js" /* webpackChunkName: "component---src-pages-neuigkeiten-js" */),
  "component---src-pages-satzung-js": () => import("./../../../src/pages/satzung.js" /* webpackChunkName: "component---src-pages-satzung-js" */),
  "component---src-pages-sponsor-werden-js": () => import("./../../../src/pages/sponsor_werden.js" /* webpackChunkName: "component---src-pages-sponsor-werden-js" */),
  "component---src-pages-sponsoren-js": () => import("./../../../src/pages/sponsoren.js" /* webpackChunkName: "component---src-pages-sponsoren-js" */),
  "component---src-pages-sport-mit-handicap-js": () => import("./../../../src/pages/sport_mit_handicap.js" /* webpackChunkName: "component---src-pages-sport-mit-handicap-js" */),
  "component---src-pages-stammtisch-js": () => import("./../../../src/pages/stammtisch.js" /* webpackChunkName: "component---src-pages-stammtisch-js" */),
  "component---src-pages-stellenanzeigen-js": () => import("./../../../src/pages/stellenanzeigen.js" /* webpackChunkName: "component---src-pages-stellenanzeigen-js" */),
  "component---src-pages-struktur-js": () => import("./../../../src/pages/struktur.js" /* webpackChunkName: "component---src-pages-struktur-js" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/verein.js" /* webpackChunkName: "component---src-pages-verein-js" */)
}

